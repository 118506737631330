import { useApp } from "@/models/app";
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button } from "antd";
import { observer } from "mobx-react";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";
import URI from "urijs";
import styles from "./index.module.scss";

const SearchAutoComplete: React.FC<{
	data: any[];
	onSearch?: (keyword: string) => void;
}> = observer(({ data, onSearch, ...props }) => {
	const searchParams = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search"));
	const MApp = useApp();
	const [options, setOptions] = useState(
		data?.map((i) => ({
			value: i,
			label: (
				<div className="item">
					<SearchOutlined /> {i}
				</div>
			),
		}))
	);
	const router = useRouter();
	useEffect(() => {
		setOptions(
			data?.map((i) => ({
				value: i,
				label: (
					<div className="item">
						<SearchOutlined />
						{i}
					</div>
				),
			}))
		);
	}, [data]);
	const handleSearch = (searchText: any) => {
		const filteredOptions = options.filter((option) =>
			option.value.toLowerCase().includes(searchText.toLowerCase())
		);
		if (!!filteredOptions?.length && searchText) {
			setOptions(filteredOptions);
		} else {
			setOptions(
				data?.map((i) => ({
					value: i,
					label: (
						<div className="item">
							<SearchOutlined />
							{i}
						</div>
					),
				}))
			);
		}
	};

	useEffect(() => {
		setSearch(searchParams.get("search"));
	}, [searchParams.get("search")]);

	const handleEnter = () => {
		const value = search || "AirPods";
		onSearch?.(value);
		const uri = new URI("/search");
		uri.setSearch("search", value);
		router.push(uri.readable());
	};

	const handleSelect = (value: any) => {
		const uri = new URI("/search");
		uri.setSearch("search", value);
		router.push(uri.readable());
	};

	return (
		<AutoComplete
			aria-label="search"
			style={{ width: "100%" }}
			className={styles["search-container"]}
			placeholder="AirPods"
			variant="borderless"
			options={options}
			value={search}
			onChange={setSearch}
			onSearch={handleSearch}
			allowClear
			size="large"
			onKeyDown={(e) => {
				if (e.code === "Enter") {
					handleEnter();
				}
			}}
			suffixIcon={
				<Button type="text" onClick={handleEnter}>
					<SearchOutlined style={{ color: "#fff" }} />
				</Button>
			}
			onSelect={handleSelect}
		></AutoComplete>
	);
});

export default SearchAutoComplete;
