"use client";
import SearchAutoComplete from "@/components/Search";
import { useApp } from "@/models/app";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

const Menu = observer(() => {
	const router = useRouter();
	const MApp = useApp();

	const goUserCenter = () => {
		router.push("/user");
	};
	const goSign = () => {
		if (MApp.appUser?.name) {
			MApp.setAppUser(undefined);
		} else {
			router.push("/login");
		}
	};

	const [history, setHistory] = useState<string[]>([]);
	useEffect(() => {
		const l = localStorage.getItem("searchHistory");
		const savedHistory = (l && JSON.parse(l)) || [];
		setHistory(savedHistory);
	}, []);

	useEffect(() => {
		localStorage.setItem("searchHistory", JSON.stringify(history));
	}, [history]);

	const enterSearch = (keyword: string) => {
		const set = new Set([keyword, ...history]);
		if (keyword) {
			setHistory([...Array.from(set)]);
		}
	};

	return (
		<div id="navContainer" className="nav">
			<p className="disclaimers">
				Itopick is community-supported.We may get paid by brands or deals,
				including promoted items.
			</p>

			<div className="nav-content">
				<Link className="logo" href="/" aria-label="logo"></Link>
				<SearchAutoComplete data={history} onSearch={enterSearch} />
				<div className="nav-btn-list user-select-none">
					{/* <div id="dropAlertBtn" onClick={goUserCenter}>
            <div className="icon icon-dropAlert">
              <div className="badge">3</div>
            </div>
            <p>drop alert</p>
          </div>
          <div id="addADealBtn">
            <div className="icon icon-addADeal"></div>
            <p>add a deal</p>
          </div>
          <div id="toggleLanguageBtn">
            <div className="icon icon-Cn"></div>
            <p>中文版</p>
          </div>
          <div id="navSigninBtn" onClick={goSign}>
            <div className="icon icon-signin"></div>
            <p>{MApp.appUser?.name ?"signout":"signin"}</p>
          </div> */}
				</div>
			</div>
		</div>
	);
});
export default Menu;
