"use client";
import { sendGAEvent } from "@next/third-parties/google";
import { usePathname, useSearchParams } from "next/navigation";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { PropsWithChildren, useEffect } from "react";

export const AnalyticsProvider: React.FC<
	PropsWithChildren<{
		posthogApiKey: string;
		gaId: string;
	}>
> = ({ children, posthogApiKey, gaId }) => {
	useEffect(() => {
		posthog.init(posthogApiKey, {
			api_host: "https://us.i.posthog.com",
			person_profiles: "identified_only",
			capture_pageview: false, // Disable automatic pageview capture, as we capture manually
			capture_pageleave: true, // Enable pageleave capture
		});
	}, []);
	return (
		<PostHogProvider client={posthog}>
			{children}
			<PageChangeTracker />
		</PostHogProvider>
	);
};

function PageChangeTracker() {
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const posthog = usePostHog();

	// Track pageviews
	useEffect(() => {
		if (pathname && posthog) {
			posthog.capture("$pageview", { $current_url: window.location.href });
		}
		sendGAEvent("event", "pageview", {
			page: window.location.href,
		});
	}, [pathname, searchParams, posthog]);

	return null;
}
