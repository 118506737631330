"use client";
import classNames from "classnames";
import { AdUnit } from "next-google-adsense";
import styles from "./index.module.scss";

const InFeedAd = () => {
	return (
		<ins
			className={classNames("adsbygoogle", styles["ads"])}
			data-ad-client="ca-pub-3450419684738781"
			data-ad-slot="1764734776"
		></ins>
	);
};
export const Ads: React.FC = () => {
	return (
		<AdUnit
			publisherId="pub-3450419684738781"
			slotId="1764734776"
			layout="custom"
			customLayout={<InFeedAd />}
		/>
	);
};
